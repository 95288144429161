import I18n from "./i18";

export function translate(key: string): string {
  if (!key) return "";
  const translation = I18n.t(key);
  return typeof translation === "string" ? translation : "";
}

export const getInterFontByLanguage = () => {
  const lang = localStorage.getItem('appLanguage')
  switch (lang) {
    case "en": 
      return "Inter";
    case "ar": 
      return "Cairo";
  }
}

export const getPoppinsFontByLanguage = () => {
  const appLanguage = localStorage.getItem('appLanguage');
  switch (appLanguage) {
    case "en":
      return "Poppins";
    case "ar":
      return "Cairo";
    default:
      return "Poppins";
  }
};

export const getDirectionsByLanguage = () => {
  const appLanguage = localStorage.getItem('appLanguage');
  switch (appLanguage) {
    case "en":
      return "ltr";
    case "ar":
      return "rtl";
    default:
      return "ltr"; 
  }
};

export const getTextAlignByLanguage = (): 'left' | 'right' | 'center' | 'justify' | 'start' | 'end' | undefined => {
  const appLanguage = localStorage.getItem('appLanguage');
  if (appLanguage === 'ar') {
    return 'end';
  }
  return 'start'; 
};

export const getTextAlignRightByLanguage = (): 'left' | 'right' | 'center' | 'justify' | 'start' | 'end' | undefined => {
  const appLanguage = localStorage.getItem('appLanguage');
  if (appLanguage === 'ar') {
    return 'right';
  }
  return 'start'; 
};


export const getDirectionLeftByLanguage = (): 'row' | 'row-reverse' | 'column' | 'column-reverse' | undefined => {
  const appLanguage = localStorage.getItem('appLanguage');
  if (appLanguage === 'ar') {
    return 'row-reverse';
  }
}

export const getAlignmentRightByLanguage = (): 'flex-end' | 'center' | 'stretch' | 'baseline' | "flex-start" | "normal" | undefined => {
  const appLanguage = localStorage.getItem('appLanguage');
  if (appLanguage === 'ar') {
    return 'flex-end';
  }
}

export const getFontSizeByLanguage = () => {
  const appLanguage = localStorage.getItem('appLanguage');
  if (appLanguage === 'ar') {
    return '16px';
  }
}