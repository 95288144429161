import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";



interface ShariaComplianceAttributes {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    title_ar: string;
    description_ar: string;
  };
}



interface ShariaCompliance {
  id: string;
  type: string;
  attributes: ShariaComplianceAttributes;
}

interface ShariaComplianceState {
  attributes: any;
  shariaComplianceData: ShariaCompliance; 
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: object;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  ShariaCompliancedata:ShariaComplianceState[];
  selectLanguage:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class ShariaComplianceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userDataListCall:string|Message=''
  addDataCall:string|Message=''
  shariaComplianceDataApiCallId:string|Message=''
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      ShariaCompliancedata:[] ,
      selectLanguage:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.shariaComplianceDataApiCallId !== null &&
      this.shariaComplianceDataApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson1 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson1) {
        this.setState({
           ShariaCompliancedata: responseJson1.data,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
        
      }
      
    }
   

    
    // Customizable Area End
  }

  // Customizable Area Start

async componentDidMount() {
  this.getTermsandConditionData();
  const languages = await getStorageData("appLanguage") || "ar";
    this.setState({ selectLanguage: languages })
}

getTermsandConditionData = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const shariaCompliancerequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.shariaComplianceDataApiCallId = shariaCompliancerequestMessage.messageId;

    shariaCompliancerequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.shariaComplianceApi
    );

    shariaCompliancerequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    shariaCompliancerequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(shariaCompliancerequestMessage.id, shariaCompliancerequestMessage);
    return true;
  };

  // Customizable Area End
}
