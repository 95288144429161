import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
interface PrivacyPolicyAttributes {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    title_ar: string;
    description_ar: string;
  };
}

interface PrivacyPolicy {
  id: string;
  type: string;
  attributes: PrivacyPolicyAttributes;
}

interface PrivacyPolicyState {
  attributes: any;
  privacyPolicyData: PrivacyPolicy; 
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: object;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  privacyPolicyDatadata:PrivacyPolicyState[];
  selecteLanguage:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PrivacyPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userDataListCall:string|Message=''
  addDataCall:string|Message=''
  privacyPolicyDataApiCallId:string|Message=''
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      privacyPolicyDatadata:[] ,
      selecteLanguage:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.privacyPolicyDataApiCallId !== null &&
      this.privacyPolicyDataApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
      if (responseJson1) {
        this.setState({
           privacyPolicyDatadata: responseJson1.data,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
        
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getPrivacyPolicyData();
    const language = await getStorageData("appLanguage") || "ar";
      this.setState({ selecteLanguage: language }, () => {
        console.log("aditya", this.state.selecteLanguage)
      })
  }
  
  getPrivacyPolicyData = async () => {
      this.setState({ loading: true });
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };
  
      const privacyPolicyrequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.privacyPolicyDataApiCallId = privacyPolicyrequestMessage.messageId;
  
      privacyPolicyrequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.privacyPolicyApi
      );
  
      privacyPolicyrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      privacyPolicyrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getMethod
      );
  
      runEngine.sendMessage(privacyPolicyrequestMessage.id, privacyPolicyrequestMessage);
      return true;
    };
  // Customizable Area End
}
